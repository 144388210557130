@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
#root {
  margin: 0px;
  height: 100%;
  background-color: white;
  cursor: default;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #333333;
}

::-webkit-scrollbar-thumb {
  background: #ffffff88;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff88;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
